
import api from "@Core/Apis/api";
import apipaths from "@Core/Apis/apiPaths";
//import Apipaths from  '@Core/Apis/apiPaths'
//import { ActionTypes } from 'Redux/Contants/actionTypes'

const ServiceAction = {
  GetState: function (cb) {
    return (dispatch) => {
      api.setMethod("get").sendRequest(apipaths.GetState, {}, true, function (response) {
        dispatch({
          type: "GetState",
          data: response.data.Entity ? response.data.Entity : {}
        })
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  GetCity: function (data, cb) {
    return (dispatch) => {
      api.setMethod("post").sendRequest(apipaths.GetCity, data, true, function (response) {
        dispatch({
          type: "GetCity",
          data: response.data.Entity ? response.data.Entity : {}
        })
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  GetServiseCenterList: function (data, cb) {
    return (dispatch) => {
      api.setMethod("post").sendRequest(apipaths.GetServiseCenterList, data, true, function (response) {
        dispatch({
          type: "GetServiseCenterList",
          data: response.data && response.data.Entity ? response.data.Entity : {}
        })
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  GetServiceCenterDetailsForCode: function (data, cb) {
    return (dispatch) => {
      api.setMethod("post").sendRequest(apipaths.GetServiceCenterDetailsForCode, data, true, function (response) {
        dispatch({
          type: "GetServiceCenterDetailsForCode",
          data: response.data && response.data.Entity ? response.data.Entity : {}
        })
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  GetServiseCenterDetail: function (token, data, cb) {
    return (dispatch) => {
      api.setMethod("post").setHeader('Token', token).sendRequest(apipaths.GetServiseCenterDetail, data, true, function (response) {
        dispatch({
          type: "GetServiseCenterDetail",
          data: response.data && response.data.Entity ? response.data.Entity : {}
        })
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  GetTimeSlotByDate: function (data, cb) {
    return (dispatch) => {
      api.setMethod("post").sendRequest(apipaths.GetTimeSlotByDate, data, true, function (response) {
        dispatch({
          type: "GetTimeSlotByDate",
          data: response.data && response.data.Entity ? response.data.Entity : {}
        })
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  GetAppointmentDate: function (data, cb) {
    return (dispatch) => {
      api.setMethod("post").sendRequest(apipaths.GetAppointmentDate, data, true, function (response) {
        dispatch({
          type: "GetAppointmentDate",
          data: response.data && response.data.Entity ? response.data.Entity : {}
        })
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  GetExistingAppointments: function (token, data, cb) {
    return (dispatch) => {
      api.setMethod("post").setHeader('Token', token).sendRequest(apipaths.GetExistingAppointments, data, true, function (response) {
        dispatch({
          type: "GetExistingAppointments",
          data: response.data && response.data.Entity ? response.data.Entity : {}
        })
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  CancelAppointment: function (token, data, cb) {
    return (dispatch) => {
      api.setMethod("post").setHeader('Token', token).sendRequest(apipaths.CancelAppointment, data, true, function (response) {
        dispatch({
          type: "CancelAppointment",
          data: response.data && response.data.Entity ? response.data.Entity : {}
        })
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  CreateAppointment: function (token, data, cb) {
    return (dispatch) => {
      api.setMethod("post").setHeader('Token', token).sendRequest(apipaths.CreateAppointment, data, true, function (response) {
        dispatch({
          type: "CreateAppointment",
          data: response.data && response.data.Entity ? response.data.Entity : {}
        })
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  SaveAppointment: function (data, cb) {
    return (dispatch) => {
      api.setMethod("post").sendRequest(apipaths.SaveAppointment, data, true, function (response) {
        dispatch({
          type: "SaveAppointment",
          data: response.data && response.data.Entity ? response.data.Entity : {}
        })
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  ReAppointment: function (token, data, cb) {
    return (dispatch) => {
      api.setMethod("post").setHeader('Token', token).sendRequest(apipaths.ReAppointment, data, true, function (response) {
        dispatch({
          type: "ReAppointment",
          data: response.data && response.data.Entity ? response.data.Entity : {}
        })
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  ValidateOTP: function (data, cb) {
    return (dispatch) => {
      api.setMethod("post").sendRequest(apipaths.ValidateOTP, data, true, function (response) {
        dispatch({
          type: "ValidateOTP",
          data: response.data && response.data.Entity ? response.data.Entity : {}
        })
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  SetBookAppointmentData: function (data) {
    return {
      type: "SetBookAppointmentData",
      data,
    };
  },
  SetDiyData: function (data) {
    return {
      type: "SetDiyData",
      data,
    };
  },
  searchPinCode: function (data, cb) {
    return (dispatch) => {
      api.setMethod("post").sendRequest(apipaths.searchPinCode, data, true, function (response) {
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  GetStateCity: function (data, cb) {
    return (dispatch) => {
      api.setMethod("post").sendRequest(apipaths.GetStateCity, data, true, function (response) {
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  getModelNumber: function (data, cb) {
    return (dispatch) => {
      api.setMethod("post").sendRequest(apipaths.getModelNumber, data, true, function (response) {
        dispatch({
          type: "GetModelNumber",
          data: response.data && response.data.Entity ? response.data.Entity : []
        })
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  getModelNumberHHP: function (data, cb) {
    return (dispatch) => {
      api.setMethod("post").sendRequest(apipaths.getModelNumberHHP, data, true, function (response) {
        dispatch({
          type: "getModelNumber",
          data: response.data && response.data.Entity ? response.data.Entity : []
        })
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  insertPickDropApi: function (token, data, cb) {
    return (dispatch) => {
      api.setMethod("post").setHeader('Token', token).sendRequest(apipaths.insertPickDropApi, data, true, function (response) {
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  GetExistingPickDrop: function (token, cb) {
    return (dispatch) => {
      api.setMethod("post").setHeader('Token', token).sendRequest(apipaths.GetExistingPickDrop, {}, true, function (response) {
        dispatch({
          type: "GetExistingPickDrop",
          data: response.data && response.data.Entity ? response.data.Entity : []
        })
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  GetVideos: function (token, data, cb) {
    return (dispatch) => {
      api.setMethod("post").setHeader('Token', token).sendRequest(apipaths.GetVideos, data, true, function (response) {
        dispatch({
          type: "GetVideos",
          data: response.data && response.data.Entity ? response.data.Entity : []
        })
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  insertReqSupport: function (token, data, cb) {
    return (dispatch) => {
      api.setMethod("post").setHeader('Token', token).sendRequest(apipaths.insertReqSupport, data, true, function (response) {
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  getTrackRepair: function (token, data, cb) {
    return (dispatch) => {
      api.setMethod("post").setHeader('Token', token).sendRequest(apipaths.getTrackRepair, data, true, function (response) {
        dispatch({
          type: "getTrackRepair",
          data: response.data && response.data.Entity ? response.data.Entity : []
        })
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  GetAllTrackRepairDetails: function (token, data, cb) {
    return (dispatch) => {
      api.setMethod("post").setHeader('Token', token).sendRequest(apipaths.GetAllTrackRepairDetails, data, true, function (response) {
        dispatch({
          type: "GetAllTrackRepairDetails",
          data: response.data && response.data.Entity ? response.data.Entity : []
        })
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  setAllTrackRepairDetails: function (data) {
    return {
      type: "GetAllTrackRepairDetails",
      data,
    };
  },
  GetGenerateOfferCode: function (token, data, cb) {
    return (dispatch) => {
      api.setMethod("post").setHeader('Token', token).sendRequest(apipaths.GetGenerateOfferCode, data, true, function (response) {
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },

  // Add new code

  GetUserMasterDetail: function (token, data, cb) {
    return (dispatch) => {
      api.setMethod("post").setHeader('Token', token).sendRequest(apipaths.GetUserMasterDetail, data, true, function (response) {
        dispatch({
          type: "GetUserMasterDetail",
          data: response.data && response.data.Entity ? response.data.Entity : []
        })
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },

  InsertUserMasterDetails: function (token, data, cb) {
    return (dispatch) => {
      api.setMethod("post").setHeader('Token', token).sendRequest(apipaths.InsertUserMasterDetails, data, true, function (response) {
        dispatch({
          type: "InsertUserMasterDetails",
          data: response.data && response.data.Entity ? response.data.Entity : []
        })
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  DeleteUserMasterAddress: function (token, data, cb) {
    return (dispatch) => {
      // api.setMethod("post").sendRequest(apipaths.DeleteUserMasterAddress, data, true, function (response)
      api.setMethod("post").setHeader('Token', token).sendRequest(apipaths.DeleteUserMasterAddress, data, true, function (response) {
        dispatch({
          type: "DeleteUserMasterAddress",
          data: response.data && response.data.Entity ? response.data.Entity : []
        })
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },

  /// 


  InsertSaveLater: function (token, data, cb) {
    return (dispatch) => {
      api.setMethod("post").setHeader('Token', token).sendRequest(apipaths.InsertSaveLater, data, true, function (response) {
        dispatch({
          type: "InsertSaveLater",
          data: response.data.Entity ? response.data.Entity : {},
        });
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },

  GetSaveLater: function (data, cb) {
    return (dispatch) => {
      api.setMethod("post").sendRequest(apipaths.GetSaveLater, data, true, function (response) {
        dispatch({
          type: "GetSaveLater",
          data: response.data.Entity ? response.data.Entity : {},
        });
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },

  GetDIYData: function (data, cb) {
    return (dispatch) => {
      api.setMethod("post").sendRequest(apipaths.GetDIYData, data, true, function (response) {
        dispatch({
          type: "GetDIYData",
          data: response.data.Entity ? response.data.Entity : {},
        });
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  GetSearchResults: function (data, cb) {
    return (dispatch) => {
      api.setMethod("post").sendRequest(apipaths.GetSearchResults, data, true, function (response) {
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  GetAppointmentDetailHistory: function (data, cb) {
    return (dispatch) => {
      api.setMethod("post").sendRequest(apipaths.GetAppointmentDetailHistory, data, true, function (response) {
        dispatch({
          type: "AppointmentDetailHistory",
          data: response.data.Entity ? response.data.Entity : {},
        });
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  GetCaptcha: function (data, cb) {
    return (dispatch) => {
      api.setMethod("post").sendRequest(apipaths.GetCaptcha, data, true, function (response) {
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  InsertMailQuestionFormDetails: function (data, cb) {
    return (dispatch) => {
      api.setMethod("post").setHeader('Content-Type' ,'multipart/form-data').sendRequest(apipaths.InsertMailQuestionFormDetails, data, true, function (response) {
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  GetGCICFeedbackQue: function (cb) {
    return (dispatch) => {
      api.setMethod("get").sendRequest(apipaths.GetGCICFeedbackQue, {}, true, function (response) {
        dispatch({
          type: "GetGCICFeedbackQue",
          data: response.data.Entity ? response.data.Entity : {},
        });
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  GCICFeedbackSubmit: function (token, data, cb) {
    return (dispatch) => {
      api.setMethod("post").setHeader('Token', token).sendRequest(apipaths.GCICFeedbackSubmit, data, true, function (response) {
        dispatch({
          type: "GCICFeedbackSubmit",
          data: response.data.Entity ? response.data.Entity : {},
        });
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  SetPickDropData: function (data) {
    return {
      type: "SetPickDropData",
      data,
    };
  },
  SetTrackDetailsData: function (data) {
    return {
      type: "SetTrackDetailsData",
      data,
    };
  },
  setLocation: function (data) {
    return {
      type: "setLocation",
      data,
    };
  },
};

export default ServiceAction;
