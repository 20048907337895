import React from 'react'
import { PopUp } from '../../../../Library';
const HeaderOfferBanner = (props) => {
  let {HeaderBanner} = props
  let HeaderBannerData = HeaderBanner[0]||{}
  return (
    <div className='offer-header-banner'>
      <div className='main-container'>
        <div className='d-md-flex justify-content-space-between align-items-center headerbanner-row'>
          <div className='d-flex align-items-center'>
            <img src='../img/offers/offer-dark.svg' className='img-responsive' alt='Offer Icon' />
            <span data-font-size-mo="10" data-font-size-pc="14" className='color-fff font-700 pl-3'>{ HeaderBannerData.Title ||''}<a data-font-size-mo="10" data-font-size-pc="14" class="color-fff text-underline font-700 ml-2 mr-2" id="open-modal" href="" onClick={e=>{e.preventDefault();PopUp(true, 'CouponGenerateModal')}}>{ HeaderBannerData.ShortDescription ||''}</a> { HeaderBannerData.LongDescription ||''}</span>
          </div>
          <div className='d-flex align-items-center justify-content-center'>
            {/* <a data-font-size-mo="10" data-font-size-pc="14" className='color-fff text-underline font-700 mr-3'href="" onClick={e=>{e.preventDefault();PopUp(true, 'CouponGenerateModal')}}>No Thanks</a> */}
            {/* <a data-font-size-mo="10" data-font-size-pc="14" class="color-fff text-underline font-700" id="open-modal" href="" onClick={e=>{e.preventDefault();PopUp(true, 'CouponGenerateModal')}}>Click here</a> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderOfferBanner