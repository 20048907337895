import React from "react";

import { BrowserRouter as Router } from "react-router-dom";

import ErrorBoundary from "@Core/Components/ErrorBoundary";
import ErrorView from "@Core/Components/ErrorView";
import Routes from "Routes";
import ReactGA from 'react-ga4';

ReactGA.initialize("G-KWK0D1SGEG");
function App() {
  return (
    <ErrorBoundary render={() => <ErrorView />}>
      <Router>
        <Routes/>
      </Router>
    </ErrorBoundary>
    // testing
  );
}

export default App;
