import constant from './constant'
const ServiceReducer = (state = { ...constant }, action) => {
    switch (action.type) {
        case 'init':
            return { error: false, msg: '' }
        case 'GetState':
            return { ...state, state: action.data }
        case 'GetCity':
            return { ...state, city: action.data }
        case 'GetServiseCenterList':
            return { ...state, serviseCenterList: action.data }
        case 'GetServiseCenterDetail':
            return { ...state, serviseCenterDetail: action.data }
        case 'GetTimeSlotByDate':
            return { ...state, timeSlotByDate: action.data }
        case 'GetAppointmentDate':
            return { ...state, appointmentDate: action.data }
        case 'GetExistingAppointments':
            return { ...state, existingAppointments: action.data }
        case 'SetBookAppointmentData':
            return { ...state, BookAppointmentData: action.data }
        case 'SetPickDropData':
            return { ...state, PickDropData: action.data }
        case 'GetExistingPickDrop':
            return { ...state, ExistingPickDropData: action.data }
        case 'GetModelNumber':
            return { ...state, ModleList: action.data }
        case 'setLocation':
            return { ...state, location: action.data }
        case 'SetForSubCategory':
            return { ...state, SelectedCategoryID: action.data }
        case 'SetForProduct':
            return { ...state, SelectedSubCategoryID: action.data }
        case 'SetForSparePart':
            return { ...state, SelectedProductID: action.data }
        case 'GetVideos':
            return { ...state, VideosList: action.data } 
        case 'InsertSaveLater':
            return { ...state, InsertSaveLaterList: action.data } 
        case 'GetSaveLater':
            return { ...state, GetSaveLaterList: action.data } 
        case 'SetDiyData':
            return { ...state, DiyData: action.data }
        case 'getTrackRepair':
            return { ...state, TrackRepairData: action.data } 
        case 'GetAllTrackRepairDetails':
            return { ...state, TrackRepairAllData: action.data }
        case 'GetUserMasterDetail':
            return { ...state, UserMasterDetail: action.data }
        case 'SetTrackDetailsData':
            return { ...state, TrackDetailsData: action.data }
        case 'GetServiceCenterDetailsForCode':
            return { ...state, serviseCenterList: action.data }
        case 'GetGCICFeedbackQue':
            return { ...state, FeedbackQue: action.data }
        default:
            return state
    }
}
export default ServiceReducer