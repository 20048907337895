import React, {useState } from "react";
import ScriptTag from "react-script-tag";

export const GnbFooter = (props) => {
  let itemCurrent = document.querySelectorAll(".footer-category__anchor");
  let itemActive = "footer-category__anchor--active";

  
  // let itemActive2 = "footer-category__list-wrap";
  function handleClick(e) {
    e.preventDefault();
    // itemActive2.classList.add('.footer-category__list-wrap--active')
    itemCurrent.forEach(
      (c) => (c.onclick = (e) => {
        itemCurrent.forEach((c) =>
          c.classList[(e.target === c || e.target.parentElement === c) ? "toggle" : "remove"](itemActive)
        );
      })
    );
  }


  return (
    <>
      <footer className="footer">
        <div className="footer">
          <div className="footer-column">
            <h2 className="hidden">Footer Navigation</h2>
            <div className="footer-column__item">
              <div className="footer-category">
                <h3 className="footer-category__title" id="footer-category-title-0">Product &amp; Service</h3>
                <a className="footer-category__anchor" href="#" onClick={handleClick} role="button"
                  aria-labelledby="footer-category-title-0" aria-expanded="false"
                  an-tr="nv01_footer sitemap|menu1Depth" an-ca="navigation" an-ac="footer"
                  an-la="product and service">
                  <span className="hidden" data-i18n-open="open" data-i18n-close="Close">open</span>
                  <img
                    className="icon"
                    src="img/svg-icons/open-down.svg"
                    alt="open-down"
                    focusable="false"
                  />
                </a>
                <div className="footer-category__list-wrap">
                  <ul className="footer-category__list" role="list">
                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link" href="https://www.samsung.com/in/smartphones/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="product and service:smartphones"
                        aria-label="Smartphones Smartphones">Smartphones</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link" href="https://www.samsung.com/in/tablets/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="product and service:tablets" aria-label="Tablets Tablets">Tablets</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link" href="https://www.samsung.com/in/audio-sound/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="product and service:audio sound"
                        aria-label="Audio Sound Audio Sound">Audio Sound</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link" href="https://www.samsung.com/in/watches/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="product and service:watches" aria-label="Watches Watches">Watches</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://www.samsung.com/in/apps/smart-switch/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="product and service:smart switch"
                        aria-label="Smart Switch Smart Switch">Smart Switch</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://www.samsung.com/in/mobile-accessories/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="product and service:mobile accessories"
                        aria-label="Mobile Accessories Mobile Accessories">Mobile Accessories</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link" href="https://www.samsung.com/in/tvs/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="product and service:tvs" aria-label="TVs TVs">TVs</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://www.samsung.com/in/audio-devices/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="product and service:sound devices"
                        aria-label="Sound Devices Sound Devices">Sound Devices</a>
                    </li>


                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://www.samsung.com/in/refrigerators/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="product and service:refrigerators"
                        aria-label="Refrigerators Refrigerators">Refrigerators</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://www.samsung.com/in/washers-and-dryers/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="product and service:laundry" aria-label="Laundry Laundry">Laundry</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://www.samsung.com/in/air-conditioners/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="product and service:air solutions"
                        aria-label="Air Solutions Air Solutions">Air Solutions</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://www.samsung.com/in/cooking-appliances/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="product and service:cooking appliances"
                        aria-label="Cooking Appliances Cooking Appliances">Cooking Appliances</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link" href="https://www.samsung.com/in/monitors/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="product and service:monitors"
                        aria-label="Monitors Monitors">Monitors</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://www.samsung.com/in/memory-storage/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="product and service:memory storage"
                        aria-label="Memory Storage Memory Storage">Memory Storage</a>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
            <div className="footer-column__item">
              <div className="footer-category">
                <h3 className="footer-category__title" id="footer-category-title-1">Shop</h3>
                <a className="footer-category__anchor" href="#" onClick={handleClick} role="button"
                  aria-labelledby="footer-category-title-1" aria-expanded="false"
                  an-tr="nv01_footer sitemap|menu1Depth" an-ca="navigation" an-ac="footer" an-la="shop">
                  <span className="hidden" data-i18n-open="open" data-i18n-close="Close">open</span>
                  <img
                    className="icon"
                    src="img/svg-icons/open-down.svg"
                    alt="open-down"
                  />
                </a>
                <div className="footer-category__list-wrap">
                  <ul className="footer-category__list" role="list">

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link" href="https://www.samsung.com/in/info/returns/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="shop:cancellation and return policy"
                        aria-label="Cancellation &amp; Return Policy Cancellation &amp; Return Policy">Cancellation
                        &amp; Return Policy</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://www.samsung.com/in/web/search-orders/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="shop:track order status"
                        aria-label="Track Order Status Track Order Status">Track Order Status</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://www.samsung.com/in/offer/online/20k-advantage-promotion/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="shop:20k advantage" aria-label="20K Advantage 20K Advantage">20K
                        Advantage</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://www.samsung.com/in/microsite/shop-referral/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="shop:samsung referral advantage"
                        aria-label="Samsung Referral Advantage Samsung Referral Advantage">Samsung
                        Referral Advantage</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://www.samsung.com/in/microsite/student-advantage/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="shop:samsung student advantage"
                        aria-label="Samsung Student Advantage Samsung Student Advantage">Samsung
                        Student Advantage</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://www.samsung.com/in/microsite/defence-purchase-program/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="shop:defence purchase program"
                        aria-label="Defence Purchase Program Defence Purchase Program">Defence
                        Purchase Program</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://www.samsung.com/in/store/corporate/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="shop:corporate employee program"
                        aria-label="Corporate Employee Program Corporate Employee Program">Corporate
                        Employee Program</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link" href="https://www.samsung.com/in/store/bulk/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="shop:corporate bulk purchase"
                        aria-label="Corporate Bulk Purchase Corporate Bulk Purchase">Corporate Bulk
                        Purchase</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://www.samsung.com/in/samsung-experience-store/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="shop:samsung experience store"
                        aria-label="Samsung Experience Store Samsung Experience Store">Samsung
                        Experience Store</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link" href="https://www.samsung.com/in/storelocator/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="shop:store locator" aria-label="Store Locator Store Locator">Store
                        Locator</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://www.samsung.com/in/microsite/smart-club/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="shop:smart club" aria-label="Smart Club Smart Club">Smart Club</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link" href="https://www.samsung.com/in/shop-faq/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="shop:faqs" aria-label="FAQs FAQs">FAQs</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://www.samsung.com/in/shop-terms-of-use/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="shop:terms of use" aria-label=" Terms of Use Terms of Use"> Terms of
                        Use</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://www.samsung.com/in/offer/online/savex/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="shop:grievance redressal"
                        aria-label="Grievance Redressal Grievance Redressal">Grievance Redressal</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link" href="https://www.samsung.com/in/explore/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="shop:explore" aria-label="Explore Explore">Explore</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="footer-column__item">
              <div className="footer-category">
                <h3 className="footer-category__title" id="footer-category-title-2">Support</h3>
                <a className="footer-category__anchor" href="#" onClick={handleClick} role="button"
                  aria-labelledby="footer-category-title-2" aria-expanded="false"
                  an-tr="nv01_footer sitemap|menu1Depth" an-ca="navigation" an-ac="footer"
                  an-la="support">
                  <span className="hidden" data-i18n-open="open" data-i18n-close="Close">open</span>
                  <img
                    className="icon"
                    src="img/svg-icons/open-down.svg"
                    alt="open-down"
                  />
                </a>
                <div className="footer-category__list-wrap">
                  <ul className="footer-category__list" role="list">
                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link new-window" href="https://wa.me/91180057267864"
                        target="_blank" an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation"
                        an-ac="footer" an-la="support:whatsapp us"
                        aria-label="WhatsApp Us (हिंदी/ English) WhatsApp Us (हिंदी/ English) : Open in a New Window">
                        WhatsApp Us (हिंदी/ English)
                        <svg className="icon" focusable="false">
                          <use xlinkHref="#outlink-bold" href="#outlink-bold"></use>
                        </svg>
                      </a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link new-window"
                        href="https://wa.me/91180057267864?text=hi" target="_blank"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="support:sign language"
                        aria-label="Sign Language Sign Language : Open in a New Window">
                        Sign Language
                        <svg className="icon" focusable="false">
                          <use xlinkHref="#outlink-bold" href="#outlink-bold"></use>
                        </svg>
                      </a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://www.samsung.com/in/support/email/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="support:email us" aria-label="Email Us Email Us">Email Us</a>
                       
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://www.samsung.com/in/support/contact/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="support:call us" aria-label="Call Us Call Us">Call Us</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://www.samsung.com/in/support/email-the-ceo/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="support:email the ceo" aria-label="Email the CEO Email the CEO">Email
                        the CEO</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link new-window"
                        href="//r2.community.samsung.com/t5/India/ct-p/in/" target="_blank"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="support:community"
                        aria-label="Community Community : Open in a New Window">
                        Community
                        <svg className="icon" focusable="false">
                          <use xlinkHref="#outlink-bold" href="#outlink-bold"></use>
                        </svg>
                      </a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="//www.samsung.com/in/mypage/#myd05-product-registration"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="support:product registration"
                        aria-label="Product Registration Product Registration">Product
                        Registration</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://www.samsung.com/in/support/service-center/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="support:service centre"
                        aria-label="Service Centre  Service Centre">Service Centre </a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link new-window"
                        href="https://survey3.medallia.com/?web&c=33&lng=en" target="_blank"
                        onClick={e => props.handleLogging('', { ChildMenuRedirectURL: "https://survey3.medallia.com/?web&c=33&lng=en", ChildMenuName: 'Share your opinion' }, 1, 'Share your opinion')}
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="support:give feedback"
                        aria-label="Give Feedback Give Feedback : Open in a New Window">
                        Share your opinion
                        <svg className="icon" focusable="false">
                          <use xlinkHref="#outlink-bold" href="#outlink-bold"></use>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

            </div>
            <div className="footer-column__item">
              <div className="footer-category">
                <h3 className="footer-category__title" id="footer-category-title-3">Account &amp; Community</h3>
                <a className="footer-category__anchor" href="#" onClick={handleClick} role="button"
                  aria-labelledby="footer-category-title-3" aria-expanded="false"
                  an-tr="nv01_footer sitemap|menu1Depth" an-ca="navigation" an-ac="footer"
                  an-la="account and services">
                  <span className="hidden" data-i18n-open="open" data-i18n-close="Close">open</span>
                  <img
                    className="icon"
                    src="img/svg-icons/open-down.svg"
                    alt="open-down"
                  />
                </a>
                <div className="footer-category__list-wrap">
                  <ul className="footer-category__list" role="list">

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link" href="https://www.samsung.com/in/mypage/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="account and services:my page" aria-label="My Page My Page">My
                        Page</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://www.samsung.com/in/mypage/myproducts/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="account and services:my products"
                        aria-label="My Products My Products">My Products</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://www.samsung.com/in/web/mypage/orders/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="account and services:orders" aria-label="Orders Orders">Orders</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://www.samsung.com/in/web/mypage/wishlist/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="account and services:wishlist"
                        aria-label="Wishlist Wishlist">Wishlist</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://www.samsung.com/in/web/mypage/vouchers/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="account and services:vouchers"
                        aria-label="Vouchers Vouchers">Vouchers</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://www.samsung.com/in/web/my-referrals/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="account and services:referrals"
                        aria-label="My Referrals My Referrals">My Referrals</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://www.samsung.com/in/support/your-service/main/?mysamsung=register"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="account and services:service"
                        aria-label="Service Service">Service</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://r2.community.samsung.com/t5/India/ct-p/in?profile.language=en"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="account and services:community"
                        aria-label="Community Community">Community</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="footer-column__item">
              <div className="footer-category">
                <h3 className="footer-category__title" id="footer-category-title-4">Sustainability</h3>
                <a className="footer-category__anchor" href="#" onClick={handleClick} role="button"
                  aria-labelledby="footer-category-title-4" aria-expanded="false"
                  an-tr="nv01_footer sitemap|menu1Depth" an-ca="navigation" an-ac="footer"
                  an-la="sustainability">
                  <span className="hidden" data-i18n-open="open" data-i18n-close="Close">open</span>
                  <img
                    className="icon"
                    src="img/svg-icons/open-down.svg"
                    alt="open-down"
                  />
                </a>
                <div className="footer-category__list-wrap">
                  <ul className="footer-category__list" role="list">
                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://www.samsung.com/in/sustainability/overview/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="sustainability:overview" aria-label="Overview Overview">Overview</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://www.samsung.com/in/sustainability/environment/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="sustainability:environment"
                        aria-label="Environment Environment">Environment</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link new-window"
                        href="http://csr.samsung.com/en-in/localMain.do" target="_blank"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="sustainability:corporate citizenship"
                        aria-label="Corporate Citizenship Corporate Citizenship : Open in a New Window">
                        Corporate Citizenship
                        <svg className="icon" focusable="false">
                          <use xlinkHref="#outlink-bold" href="#outlink-bold"></use>
                        </svg>
                      </a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://www.samsung.com/in/sustainability/digital-responsibility/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="sustainability:digital responsibility"
                        aria-label="Digital Responsibility Digital Responsibility">Digital
                        Responsibility</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://www.samsung.com/in/sustainability/security-and-privacy/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="sustainability:security and privacy"
                        aria-label="Security &amp; Privacy Security &amp; Privacy">Security &amp;
                        Privacy</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://www.samsung.com/in/sustainability/accessibility/overview/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="sustainability:accessibility"
                        aria-label="Accessibility Accessibility">Accessibility</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://www.samsung.com/in/sustainability/labor-and-human-rights/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="sustainability:labor and human rights"
                        aria-label="Labor &amp; Human Rights Labor &amp; Human Rights">Labor &amp;
                        Human Rights</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://www.samsung.com/in/sustainability/diversity-and-inclusion/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="sustainability:diversity and inclusion"
                        aria-label="Diversity &amp; Inclusion Diversity &amp; Inclusion">Diversity
                        &amp; Inclusion</a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://www.samsung.com/in/sustainability/sustainable-supply-chain/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="sustainability:sustainable supply chain"
                        aria-label="Sustainable Supply Chain Sustainable Supply Chain">Sustainable
                        Supply Chain</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footer-category">
                <h3 className="footer-category__title" id="footer-category-title-4-2">About Us</h3>
                <a className="footer-category__anchor" href="#" onClick={handleClick} role="button"
                  aria-labelledby="footer-category-title-4-2" aria-expanded="false"
                  an-tr="nv01_footer sitemap|menu1Depth" an-ca="navigation" an-ac="footer"
                  an-la="sustainability">
                  <span className="hidden">open</span>
                  <img
                    className="icon"
                    src="img/svg-icons/open-down.svg"
                    alt="open-down"
                  />
                </a>
                <div className="footer-category__list-wrap">
                  <ul className="footer-category__list" role="list">
                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://www.samsung.com/in/about-us/company-info/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="about us:company info" aria-label="Company Info Company Info">
                        Company Info
                      </a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://www.samsung.com/in/about-us/business-area/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="about us:business area" aria-label="Business Area Business Area">
                        Business Area
                      </a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://www.samsung.com/in/about-us/brand-identity/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="about us:brand identity" aria-label="Brand Identity Brand Identity">
                        Brand Identity
                      </a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://www.samsung.com/in/about-us/careers/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="about us:careers" aria-label="Careers Careers">
                        Careers
                      </a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link new-window" href="//www.samsung.com/global/ir/"
                        target="_blank" an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation"
                        an-ac="footer" an-la="about us:investor relations"
                        aria-label="Investor Relations Investor Relations : Open in a New Window">
                        Investor Relations
                        <svg className="icon" focusable="false">
                          <use xlinkHref="#outlink-bold" href="#outlink-bold"></use>
                        </svg>
                      </a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link new-window" href="//news.samsung.com/in"
                        target="_blank" an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation"
                        an-ac="footer" an-la="about us:newsroom"
                        aria-label="Newsroom Newsroom : Open in a New Window">
                        Newsroom
                        <svg className="icon" focusable="false">
                          <use xlinkHref="#outlink-bold" href="#outlink-bold"></use>
                        </svg>
                      </a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link"
                        href="https://www.samsung.com/in/about-us/ethics/"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="about us:ethics" aria-label="Ethics Ethics">
                        Ethics
                      </a>
                    </li>

                    <li className="footer-category__item" role="listitem">
                      <a className="footer-category__link new-window"
                        href="//design.samsung.com/global/index.html" target="_blank"
                        an-tr="nv01_footer sitemap--cta-menu" an-ca="navigation" an-ac="footer"
                        an-la="about us:samsung design"
                        aria-label="Samsung Design  Samsung Design  : Open in a New Window">
                        Samsung Design
                        <svg className="icon" focusable="false">
                          <use xlinkHref="#outlink-bold" href="#outlink-bold"></use>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="footer-copyright-wrap">
              <div className="footer-copyright-align">
                <p className="footer-copyright">Copyright ⓒ 1995-2023 SAMSUNG All Rights reserved.</p>
                <div className="footer-legal"><br />Please dispose of e-waste and plastic waste
                  responsibly.<br />For more information or e-waste pick up, please call 1800 5 7267864 or
                  <a href="https://www.samsung.com/in/microsite/care-for-clean-india/"> click here</a> for
                  more details.<br /><br />Registered Office Address: 6th Floor, DLF Centre, Sansad Marg,
                  New Delhi-110001<br />Corporate Identification Number (CIN): U31900DL1995PTC071387
                </div>
              </div>
              <div className="footer-language">
                <span className="footer-language__text">India/English</span>
              </div>
            </div>
            <div className="footer-language-wrap" an-tr="nv02_footer bottom--text-scroll" an-ca="scroll"
              an-ac="scroll:100" an-la="scroll:100">
              <div className="footer-language">
                <span className="footer-language__text">India/English</span>
              </div>
              <div className="footer-terms">
                <ul className="footer-terms__list" role="list">
                  <li className="footer-terms__item" role="listitem">
                    <a className="footer-terms__link" href="https://www.samsung.com/in/info/privacy/"
                      an-tr="nv02_footer bottom--cta-bottom" an-ca="navigation" an-ac="footer"
                      an-la="bottom:legal menu:privacy" aria-label="Privacy">Privacy</a>
                  </li>

                  <li className="footer-terms__item" role="listitem">
                    <a className="footer-terms__link" href="https://www.samsung.com/in/info/legal/"
                      an-tr="nv02_footer bottom--cta-bottom" an-ca="navigation" an-ac="footer"
                      an-la="bottom:legal menu:legal" aria-label="Legal">Legal</a>
                  </li>

                  <li className="footer-terms__item" role="listitem">
                    <a className="footer-terms__link" href="https://www.samsung.com/in/info/csr-policy/"
                      an-tr="nv02_footer bottom--cta-bottom" an-ca="navigation" an-ac="footer"
                      an-la="bottom:legal menu:csr policy" aria-label="CSR Policy">CSR Policy</a>
                  </li>

                  <li className="footer-terms__item" role="listitem">
                    <a className="footer-terms__link" href="https://www.samsung.com/in/info/sitemap/"
                      an-tr="nv02_footer bottom--cta-bottom" an-ca="navigation" an-ac="footer"
                      an-la="bottom:legal menu:sitemap" aria-label="Sitemap">Sitemap</a>
                  </li>
                </ul>
              </div>
              <div className="footer-sns">
                <span className="footer-sns__title">Stay in the Loop?</span>
                <ul className="footer-sns__list" role="list">
                  <li className="footer-sns__item" role="listitem">
                    <a className="footer-sns__link" href="//www.facebook.com/SamsungIndia" target="_blank"
                      an-tr="nv02_footer bottom--cta-sns" an-ca="navigation" an-ac="footer"
                      an-la="bottom:sns:facebook" aria-label="Facebook : Open in a New Window">
                      <svg className="icon" focusable="false">
                        <use xlinkHref="#facebook-bold" href="#facebook-bold"></use>
                      </svg>
                    </a>
                  </li>

                  <li className="footer-sns__item" role="listitem">
                    <a className="footer-sns__link" href="//twitter.com/samsungindia" target="_blank"
                      an-tr="nv02_footer bottom--cta-sns" an-ca="navigation" an-ac="footer"
                      an-la="bottom:sns:twitter" aria-label="Twitter : Open in a New Window">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 1200 1227" fill="none">
                        <g clipPath="url(#clip0_1_2)"><path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z" fill="black"/></g>
                        <defs><clipPath id="clip0_1_2"><rect width="1200" height="1227" fill="white"/></clipPath></defs>
                      </svg>
                    </a>
                  </li>

                  <li className="footer-sns__item" role="listitem">
                    <a className="footer-sns__link" href="//www.instagram.com/samsungindia/" target="_blank"
                      an-tr="nv02_footer bottom--cta-sns" an-ca="navigation" an-ac="footer"
                      an-la="bottom:sns:instagram" aria-label="Instagram : Open in a New Window">
                      <svg className="icon" focusable="false">
                        <use xlinkHref="#instagram-bold" href="#instagram-bold"></use>
                      </svg>
                    </a>
                  </li>

                  <li className="footer-sns__item" role="listitem">
                    <a className="footer-sns__link" href="//www.youtube.com/SamsungMobileIndia"
                      target="_blank" an-tr="nv02_footer bottom--cta-sns" an-ca="navigation"
                      an-ac="footer" an-la="bottom:sns:youtube"
                      aria-label="Youtube : Open in a New Window">
                      <svg className="icon" focusable="false">
                        <use xlinkHref="#youtube-bold" href="#youtube-bold"></use>
                      </svg>
                    </a>
                  </li>

                  <li className="footer-sns__item" role="listitem">
                    <a className="footer-sns__link" href="//www.linkedin.com/company/samsung-india"
                      target="_blank" an-tr="nv02_footer bottom--cta-sns" an-ca="navigation"
                      an-ac="footer" an-la="bottom:sns:linkedin"
                      aria-label="Linkedin : Open in a New Window">
                      <svg className="icon" focusable="false">
                        <use xlinkHref="#linked-in-bold" href="#linked-in-bold"></use>
                      </svg>
                    </a>
                  </li>

                  <li className="footer-sns__item" role="listitem">
                    <a className="footer-sns__link" href="//bit.ly/31QN4lI" target="_blank"
                      an-tr="nv02_footer bottom--cta-sns" an-ca="navigation" an-ac="footer"
                      an-la="bottom:sns:whatsapp" aria-label="WhatsApp : Open in a New Window">
                      <svg className="icon" focusable="false">
                        <use xlinkHref="#whatsapp-bold" href="#whatsapp-bold"></use>
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <ScriptTag type="text/javascript" src="js/clientlib-base.min.js" />
    </>
  );
};
