import React from 'react'
import { useState } from 'react';
import { PopUp } from './../../Library';
import { toast } from 'react-toastify';
import actions from "../../../Redux/Action";
import { useDispatch } from 'react-redux'
import { useEffect } from 'react';
 const CouponGenerate = (props) => {
    const dispatch = useDispatch()
   
    const [mobileNumber,setMobileNumber] = useState('')
    const handleCouponGenrate =(e)=>{
        e.preventDefault()
        let error = {};
       if(!mobileNumber){
        toast.warn("Please Enter Mobile Number!")
        return;
       }
       if(mobileNumber && mobileNumber.length<10){
        toast.warn("Please Enter 10 Digit Mobile Number!")
        return;
       }

       dispatch(actions.CommonActions.GetCouponGenerationURL({"MobileNo": mobileNumber},(res)=>{
        if(res.IsSuccess){
            let RedirectionURL = res.Entity && res.Entity[0] && res.Entity[0].RedirectionURL || ''
            props.handleLogging(e, { ChildMenuRedirectURL: RedirectionURL, ChildMenuName: "Generate Offer Coupon" }, 1, 'Offer Management')
           // window.location.href = RedirectionURL
           PopUp(false, 'CouponGenerateModal')
           setMobileNumber('')
           window.open(RedirectionURL,'_blank')
        }else{
            toast.error(res.Message);
        }
       }))
    }
    const handleMobileNumber =(e)=>{
        let {value} = e.target
        if (value.length > 10) return;
        value = String(value).replace(/[^\d]/g, "");
        setMobileNumber(value)
    }
    return (
        <div className="modal hidden registration generate-coupon-modal" id='CouponGenerateModal'>
            
            <h2 className="modal__header search-product-card__title mb-3 p-0 text-center" data-font-size-mo="20" data-font-size-pc="32">
                Generate Coupon{" "}
            </h2>
            <form>
             
                <label className='mb-2 d-block'>Enter Mobile Number</label>
                <input aria-hidden="false" title='Mobile No.' className="form-control" type="text" placeholder="Mobile Number" name="mobileNumber" value={mobileNumber || ""} onChange={handleMobileNumber} />
            </form>
            <div className="align-center margin-top--32 margin-bottom--16">
                <a className="cta cta--outlined cta--black close-modal" href="" onClick={(e) => { e.preventDefault(); PopUp(false, 'CouponGenerateModal'); setMobileNumber('')}} > Cancel </a>
                <a className="cta cta--contained cta--black ml-2" href="" onClick={handleCouponGenrate}>Submit</a>
            </div>
        </div>
    )
}
export default CouponGenerate