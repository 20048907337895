import React, { useEffect } from 'react'
import { PopUp } from '../../Library';
import Slider from "react-slick";
import MagicSliderDots from 'react-magic-slider-dots';
import { useState } from 'react';
const OfferBannerModal = (props) => {
  let { PopUpBanner } = props
  const [bannerFlag, setBannerFlag] = useState(true)
  useEffect(() => {
    let pathName = window && window.location && window.location.pathname ? window.location.pathname : '/'
    if (pathName !== '/' && bannerFlag) {
      setBannerFlag(false)
      setTimeout(() => {
        PopUp(true, 'OfferBannerModalModal')
      }, 5000)
    }
  })
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,

    adaptiveHeight: true,
    appendDots: dots => {
      return <MagicSliderDots dots={dots} numDotsToShow={3} dotWidth={20} />;
    }
  };
  const handleOfferBaner = (e, flag = false) => {
    e.preventDefault()
    PopUp(false, 'OfferBannerModalModal')
    flag && PopUp(true, 'CouponGenerateModal')
    setBannerFlag(false)
  }
  return (
    <div className="modal hidden registration offer-modal" id='OfferBannerModalModal'>
      <a href='' className='close-btn' onClick={e => handleOfferBaner(e)}>
        <img className="responsive-img" src={'/img/offers/Close.svg'} alt={'close'} />
      </a>
      <Slider {...settings}  >
        {PopUpBanner && PopUpBanner.length > 0 ? PopUpBanner.map(item => {

          return <li className='' key={`offerBanner-${item.PromotionRecordID}`}>
            <a
              href=''
              target="_self"
              onClick={e => e.preventDefault()}
            >
              <div className="image image--main-loaded">
                {(props.isMobile && item.BannerMobileImage_Byte) ?
                  <img
                    className="image__main responsive-img image--loaded"
                    src={`data:image/${item.MobileExtention.replace(/^\./, '')};base64,${item.BannerMobileImage_Byte}`}
                    alt={'Offer banner'}
                    onClick={e => handleOfferBaner(e, true)}
                  />
                  :
                  <img
                    className="image__main responsive-img image--loaded"
                    src={`data:image/${item.WebExtention.replace(/^\./, '')};base64,${item.BannerWebImage_Byte}`}
                    alt={'Offer banner'}
                    onClick={e => handleOfferBaner(e, true)}
                  />
                }
              </div>
            </a>
          </li>
        }) : ''}
      </Slider>
    </div>

  )
}
export default OfferBannerModal