import React from "react";
import Slider from "react-slick";
import MagicSliderDots from 'react-magic-slider-dots';

const PromotionImages = (props) => {
    let bannerImages = props.PromotionBnner || []
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        appendDots: dots => {
            return <MagicSliderDots dots={dots} numDotsToShow={3} dotWidth={20} />;
        }
    };
    return (
        <div className="su11-link-card__wrap promo-wrap padding-top--16 padding-bottom--48 margin-bottom--48 custom-slick-width">
            <div className={` ${!props.isMobile ? 'contactguid' : ''}  `}>
                <Slider  {...settings} >
                    {bannerImages && bannerImages.length && bannerImages.map((item, i) => {
                        if (props.isMobile && item.BannerMobileImage_Byte) {
                            return <div key={i}>
                                <a href={item.BannerURL} target="_blank" onClick={e => props.handleLogging('', { ChildMenuRedirectURL: item.BannerURL, ChildMenuName: item.Title, ChildCatogoryCode: item.Type }, 1, 'Promotion Banner')} >
                                    <img className="image__main image--loaded" src={`data:image/${item.MobileExtention.replace(/^\./, '')};base64,${item.BannerMobileImage_Byte}`} />
                                </a>
                            </div>
                        } else if (item.BannerWebImage_Byte) {
                            return <div key={i}>
                                <a href={item.BannerURL} target="_blank" onClick={e => props.handleLogging('', { ChildMenuRedirectURL: item.BannerURL, ChildMenuName: item.Title, ChildCatogoryCode: item.Type }, 1, 'Promotion Banner')} >
                                    <img className="image__main image--loaded" src={`data:image/${item.WebExtention.replace(/^\./, '')};base64,${item.BannerWebImage_Byte}`} />
                                </a>
                            </div>
                        }
                    })}
                </Slider>
            </div>
        </div>
    );
}

export default PromotionImages